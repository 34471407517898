import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';
export const FRENCH_ASSET_NAME = 'landing-page-french'
export const GERMAN_ASSET_NAME = 'landing-page-german'

function getAssetName (language){
  switch(language){
    case 'fr':
      return FRENCH_ASSET_NAME
    case 'de':
      return GERMAN_ASSET_NAME
    default:
     return ASSET_NAME
  }
}

export const loadData = (params, search, config, lang) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${getAssetName(lang)}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
