import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
import { languageType } from '../../util/languageType';

export const loadData = (params, search) => async dispatch => {
  let pageId = params.pageId;
  let defaultPage=pageId
  console.log("languageu",languageType())
  try{
  
  pageId=languageType()==="fr"?`${pageId}-french`:languageType()==="de"?`${pageId}-german`:pageId

  
  const pageAsset = { [pageId]: `content/pages/${pageId}.json` };
  const hasFallbackContent = false;
  const res=await dispatch(fetchPageAssets(pageAsset, hasFallbackContent,pageId));
  if(!res){
    const pageAsset = { [defaultPage]: `content/pages/${defaultPage}.json` };
  
   dispatch(fetchPageAssets(pageAsset, hasFallbackContent));
  }
  // else{
 
  // }
  }
  catch(e){
    console.log("eeee",e)
  }
 

};
