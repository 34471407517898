export const languageType=()=>{
    let languageCokieValue;
    if (typeof window != 'undefined') {
      languageCokieValue =
      document.cookie.match('(^|;)\\s*' + 'language' + '\\s*=\\s*([^;]+)')?.pop() || 'en-US';
    } else {
      languageCokieValue = 'en-US';
    }
    return languageCokieValue
  
  }